<template>
  <div>
    <div class="facilityBox">
      <el-tabs v-model="currentStep" @tab-click="stepChange">
        <!-- 货主 -->
        <el-tab-pane label="正在申请" name="1"></el-tab-pane>
        <el-tab-pane label="确认付款" name="3"></el-tab-pane>
        <el-tab-pane label="核对发票" name="4"></el-tab-pane>
      </el-tabs>
      <Wait v-if="currentStep == 1" @uploadVoucher="uploadVoucher"></Wait>
      <ConfirmPayment v-if="currentStep == 3" @uploadVoucher="uploadVoucher"></ConfirmPayment>
      <Agree v-if="currentStep == 4" @uploadVoucher="uploadVoucher"></Agree>

      <el-dialog
        width="1200px"
        title="线下付款凭证"
        center
        :visible.sync="dialogVisible">
        <UploadFile ref="uploadFile" @uploadVoucher="uploadVoucher"></UploadFile>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import Agree from './cmpt/agree'
import ConfirmPayment from './cmpt/confirmPayment'
import Wait from './cmpt/wait'
import UploadFile from '@/components/uploadFile/uploadFile'
export default {
  data() {
    return {
      currentStep: '1', //当前处于第几步
      id: '', //待办列表id
      dialogVisible: false
    }
  },
  methods: {
    stepChange(e) {
      this.currentStep = String(e.name)
    },

    // 打开凭证dialog
    uploadVoucher(e) { 
      this.dialogVisible = e
      
      this.$nextTick(function() { 
        this.$refs.uploadFile.getUploadFile()
      })

    }

  },
  created() {
    this.id = this.$route.params.id
    this.currentStep = this.$route.query.activeCmpt ? this.$route.query.activeCmpt : '1'
  },
  components: {
    Agree,
    ConfirmPayment,
    Wait,
    UploadFile
  }
}
</script>

<style lang="scss" scoped>
</style>